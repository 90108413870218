const actions = {
  GET_DATA_BAR: 'GET_DATA_BAR',
  SET_DATA_BAR: 'SET_DATA_BAR',
  getBarData: () => {
    return {
      type: actions.GET_DATA_BAR,
    };
  },
  setBarData: data => {
    return {
      type: actions.SET_DATA_BAR,
      data,
    };
  },
  
};

export default actions;
