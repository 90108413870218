import actions from './actions';
import colonias from '../../demoData/colonias_cp.json';

const {
  SET_DATA_SEC,
  SET_DATA_SEC_FILTER,
  SET_DATA_TYPE,
  SET_DATA_MUN,
  SET_DATA_DLS,
  SET_DATA_EST,
  SET_DATA_MOVI,
  SET_DATA_ANA,
  SET_DATA_RESP,
  SET_DATA_CAPT,
  SET_DATA_COL,
  SET_DATA_COO,
  SET_DATA_BRA,
  SET_DATA_COU,
  SET_DATA_SALE,
  SET_FILTER_SALE,
  SET_DATA_REPORT,
} = actions;

const initialState = {
  sections: [],
  analytics: [],
  sectionsFilter: [],
  tiposUsuarios: [],
  municipios: [],
  distritosLocales: [],
  estructuras: [],
  movilizadores: [],
  responsables: [],
  capturistas: [],
  coordinadores: [],
  colonias: colonias,
  //NEW
  branchs: [],
  courses: [],
  sales: [],
  report: {
    totalBox:0,
    payments:[],
    expenses:[],
  },
};


const coreReducer = (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case SET_DATA_SEC:
      return {
        ...state,
        sections: data
      };
    case SET_FILTER_SALE:
      const sales = state.sales.filter(sale => sale.saleTypes === data);
      console.log('SET_FILTER_SALE', sales);
      return {
        ...state,
        sales
      };
    case SET_DATA_SEC_FILTER:
      return {
        ...state,
        sectionsFilter: data
      };
    case SET_DATA_TYPE:
      return {
        ...state,
        tiposUsuarios: data
      };
    case SET_DATA_MUN:
      return {
        ...state,
        municipios: data
      };
    case SET_DATA_DLS:
      return {
        ...state,
        distritosLocales: data
      };
    case SET_DATA_EST:
      return {
        ...state,
        estructuras: data
      };
    case SET_DATA_MOVI:
      return {
        ...state,
        movilizadores: data
      };
    case SET_DATA_CAPT:
      return {
        ...state,
        capturistas: data
      };
    case SET_DATA_ANA:
      return {
        ...state,
        analytics: data
      };
    case SET_DATA_RESP:
      return {
        ...state,
        responsables: data
      };
    case SET_DATA_COL:
      return {
        ...state,
        colonias: data
      };
    case SET_DATA_COO:
      return {
        ...state,
        coordinadores: data
      };
    case SET_DATA_BRA:
      return {
        ...state,
        branchs: data
      };
    case SET_DATA_COU:
      return {
        ...state,
        courses: data
      };
    case SET_DATA_SALE:
      return {
        ...state,
        sales: data
      };
    case SET_DATA_REPORT:
      return {
        ...state,
        report: data
      };
    default:
      return state;
  }
};
export { coreReducer };
