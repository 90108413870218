const actions = {
  GET_DATA_GROUP: 'GET_DATA_GROUP',
  SET_DATA_GROUP: 'SET_DATA_GROUP',
  EDIT_DATA_GROUP: 'EDIT_DATA_GROUP',
  DELETE_DATA_GROUP: 'DELETE_DATA_GROUP',
  GET_DATA_FINISH: 'GET_DATA_FINISH',
  GET_DATA_BEGIN: 'GET_DATA_BEGIN',
  //NEW
  SET_NEW_DATA_GROUP: 'SET_NEW_DATA_GROUP',
  SET_DATA_DETAIL_GROUP: 'SET_DATA_DETAIL_GROUP',
  SET_DATA_GROUP_ALL: 'SET_DATA_GROUP_ALL',
  SET_DATA_LESSONS: 'SET_DATA_LESSONS',
  getGroupData: () => {
    return {
      type: actions.GET_DATA_GROUP,
    };
  },
  setLessonsData: data => {
    return {
      type: actions.SET_DATA_LESSONS,
      data,
    };
  },
  setGroupData: data => {
    return {
      type: actions.SET_DATA_GROUP,
      data,
    };
  },
  setAllGroupsData: data => {
    return {
      type: actions.SET_DATA_GROUP_ALL,
      data,
    };
  },
  getDataBegin: () => {
    return {
      type: actions.GET_DATA_BEGIN,
    };
  },
  getDataFinish: () => {
    return {
      type: actions.GET_DATA_FINISH,
    };
  },
  setDeleteGroup: data => {
    return {
      type: actions.DELETE_DATA_GROUP,
      data,
    };
  },
  setEditGroupData: data => {
    return {
      type: actions.EDIT_DATA_GROUP,
      data,
    };
  },
  setNewGroupData: data => {
    return {
      type: actions.SET_NEW_DATA_GROUP,
      data,
    };
  },
  setGroupDetailData: data => {
    return {
      type: actions.SET_DATA_DETAIL_GROUP,
      data,
    };
  },
};


export default actions;
