const actions = {
  SET_DATA_SEC: 'SET_DATA_SEC',
  SET_DATA_SEC_FILTER: 'SET_DATA_SEC_FILTER',
  SET_DATA_TYPE: 'SET_DATA_TYPE',
  SET_DATA_MUN: 'SET_DATA_MUN',
  SET_DATA_DLS: 'SET_DATA_DLS',
  SET_DATA_EST: 'SET_DATA_EST',
  SET_DATA_MOVI: 'SET_DATA_MOVI',
  SET_DATA_ANA: 'SET_DATA_ANA',
  SET_DATA_RESP: 'SET_DATA_RESP',
  SET_DATA_COO: 'SET_DATA_COO',
  SET_DATA_CAPT: 'SET_DATA_CAPT',
  SET_DATA_COL: 'SET_DATA_COL',
  SET_DATA_BRA: 'SET_DATA_BRA',
  SET_DATA_COU: 'SET_DATA_COU',
  SET_DATA_SALE: 'SET_DATA_SALE',
  SET_FILTER_SALE: 'SET_FILTER_SALE',
  SET_DATA_REPORT: 'SET_DATA_REPORT',
  setAnalytics: data => {
    return {
      type: actions.SET_DATA_ANA,
      data,
    };
  },
  setSections: data => {
    return {
      type: actions.SET_DATA_SEC,
      data,
    };
  },
  setSectionFilter: data => {
    return {
      type: actions.SET_DATA_SEC_FILTER,
      data,
    };
  },
  setTiposUsuarios: data => {
    return {
      type: actions.SET_DATA_TYPE,
      data,
    };
  },
  setMunicipios: data => {
    return {
      type: actions.SET_DATA_MUN,
      data,
    };
  },
  setDistritosLocales: data => {
    return {
      type: actions.SET_DATA_DLS,
      data,
    };
  },
  setEstructuras: data => {
    return {
      type: actions.SET_DATA_EST,
      data,
    };
  },
  setMobilizers: data => {
    return {
      type: actions.SET_DATA_MOVI,
      data,
    };
  },
  setCapturistas: data => {
    return {
      type: actions.SET_DATA_CAPT,
      data,
    };
  },
  setResponsables: data => {
    return {
      type: actions.SET_DATA_RESP,
      data,
    };
  },
  setCooGral: data => {
    return {
      type: actions.SET_DATA_COO,
      data,
    };
  },
  setColonias: data => {
    return {
      type: actions.SET_DATA_COL,
      data,
    };
  },
  //NEW
  setBranchs: data => {
    return {
      type: actions.SET_DATA_BRA,
      data,
    };
  },
  setCourses: data => {
    return {
      type: actions.SET_DATA_COU,
      data,
    };
  },
  setSales: data => {
    return {
      type: actions.SET_DATA_SALE,
      data,
    };
  },
  setReport: data => {
    return {
      type: actions.SET_DATA_REPORT,
      data,
    };
  },
  filterSales: data => {
    return {
      type: actions.SET_FILTER_SALE,
      data,
    };
  },
};

  export default actions;
