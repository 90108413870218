// redux/types/reducers.js
import actions from './actions';

const {
  GET_DATA_TYPE,
  SET_DATA_TYPE,
  DELETE_DATA_TYPE,
  EDIT_DATA_TYPE,
  GET_DATA_BEGIN,
  GET_DATA_FINISH,
  SET_NEW_DATA_TYPE,
  GET_DATA_TYPES_ALL
} = actions;

const initialState = {
  data: [],
  types: [],
  total: 0,
  loading: false,
};

const typesReducer = (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case GET_DATA_TYPE:
      return {
        ...state
      };
    case SET_DATA_TYPE:
      return {
        ...state,
        data: data.results,
        total: data.totalResults,
        loading: false,
      };
    case GET_DATA_TYPES_ALL:
      return {
        ...state,
        types: data,
        loading: false,
      };
    case DELETE_DATA_TYPE:
      return {
        ...state,
        data: [...state.data.filter(type => type.id !== data.id)]
      };
    case EDIT_DATA_TYPE:
      const index = state.data.findIndex(type => type.id === data.id);
      let newArray = [...state.data];
      newArray[index] = data;
      return {
        ...state,
        data: newArray
      };
    case SET_NEW_DATA_TYPE:
      return {
        ...state,
        data: [data, ...state.data],
      };
    case GET_DATA_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_DATA_FINISH:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export { typesReducer };