export const auth0options = {
  theme: {
    primaryColor: '#351a4d',
    labeledSubmitButton: true,
  },
  rememberLastLogin: false,
  auth: {
    params: {
     scope: "openid profile email app_metadata identities"
    },
    audience: 'https://movilization.uc.r.appspot.com',
    autoParseHash: true,
    redirect: false,
    // redirectUrl: "http://localhost:3000/callback",
    responseMode: "form_post",
    responseType: "token id_token",
    sso: false,
   },
  languageDictionary: {
    title: 'Iniciar sesión',
  },
  language:'es',
  popupOptions: { width: 660, height: 606, left: 20, top: 30 },
  closable: true,
  allowSignUp:false
};
