const actions = {
  GET_DATA_TIMETABLE: 'GET_DATA_TIMETABLE',
  SET_DATA_TIMETABLE: 'SET_DATA_TIMETABLE',
  EDIT_DATA_TIMETABLE: 'EDIT_DATA_TIMETABLE',
  DELETE_DATA_TIMETABLE: 'DELETE_DATA_TIMETABLE',
  GET_DATA_FINISH: 'GET_DATA_FINISH',
  GET_DATA_BEGIN: 'GET_DATA_BEGIN',
  //NEW
  SET_NEW_DATA_TIMETABLE: 'SET_NEW_DATA_TIMETABLE',
  getTimetableData: () => {
    return {
      type: actions.GET_DATA_TIMETABLE,
    };
  },
  setTimetableData: data => {
    return {
      type: actions.SET_DATA_TIMETABLE,
      data,
    };
  },
  getDataBegin: () => {
    return {
      type: actions.GET_DATA_BEGIN,
    };
  },
  getDataFinish: () => {
    return {
      type: actions.GET_DATA_FINISH,
    };
  },
  setDeleteTimetable: data => {
    return {
      type: actions.DELETE_DATA_TIMETABLE,
      data,
    };
  },
  setEditTimetableData: data => {
    return {
      type: actions.EDIT_DATA_TIMETABLE,
      data,
    };
  },
  setNewTimetableData: data => {
    return {
      type: actions.SET_NEW_DATA_TIMETABLE,
      data,
    };
  },
  setNewTimetableData: data => {
    return {
      type: actions.SET_NEW_DATA_TIMETABLE,
      data,
    };
  },
};


export default actions;
