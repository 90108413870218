import axios from 'axios';
import { getItem, removeItem} from '../../utility/localStorageControl';
import { useAuth0 } from '@auth0/auth0-react';
import Cookies from 'js-cookie';
import { notification} from 'antd';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const authHeader = () => ({
  Authorization: `Bearer ${getItem('access_token')}`,
});

const client = axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    Authorization: `Bearer ${getItem('access_token')}`,
    'Content-Type': 'application/json',
  },
});

class DataService {
  static get(path = '') {
    return client({
      method: 'GET',
      url: path,
      headers: { ...authHeader() },
    });
  }

  static post(path = '', data = {}, optionalHeader = {}) {
    return client({
      method: 'POST',
      url: path,
      data,
      headers: { ...authHeader(), ...optionalHeader },
    });
  }

  static patch(path = '', data = {}) {
    return client({
      method: 'PATCH',
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }

  static put(path = '', data = {}) {
    return client({
      method: 'PUT',
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }

  static delete(path = '') {
    return client({
      method: 'DELETE',
      url: path,
      headers: { ...authHeader() },
    });
  }

}

/**
 * axios interceptors runs before and after a request, letting the developer modify req,req more
 * For more details on axios interceptor see https://github.com/axios/axios#interceptors
 */
client.interceptors.request.use(config => {
  // do something before executing the request
  // For example tag along the bearer access token to request header or set a cookie
  const requestConfig = config;
  const { headers } = config;
  requestConfig.headers = { ...headers, Authorization: `Bearer ${getItem('access_token')}` };
  return requestConfig;
});

client.interceptors.response.use(
  response => response,
  error => {
    /**
     * Do something in case the response returns an error code [3**, 4**, 5**] etc
     * For example, on token expiration retrieve a new access token, retry a failed request etc
     */
    const { response } = error;
    // const originalRequest = error.config;
    console.log('response interceptors ==>', response)
    if (response) {
      ;
      if (response.status === 500) {
        return response;
      } else if (response.status === 401 || response.status === 403) {
        if (response.status === 401 && response.data?.message == 'Please authenticate') {
          Cookies.remove('logedIn');
          removeItem('access_token');
          removeItem('refresh_token');
          removeItem('user')
          notification['error']({
            message: 'Autenticate',
            description: 'Su sessión a caducado',
          });
          window.location.replace(window.location.origin);
        }
        return response;
      } else {
        return response;
      }
    }
    return Promise.reject(response);
  },
);
export { DataService };
