const actions = {
  GET_DATA_STUDENT: 'GET_DATA_STUDENT',
  SET_DATA_STUDENT: 'SET_DATA_STUDENT',
  SET_DATA_DETAIL_STUDENT: 'SET_DATA_DETAIL_STUDENT',
  EDIT_DATA_STUDENT: 'EDIT_DATA_STUDENT',
  DELETE_DATA_STUDENT: 'DELETE_DATA_STUDENT',
  GET_DATA_FINISH: 'GET_DATA_FINISH',
  GET_DATA_BEGIN: 'GET_DATA_BEGIN',
  //NEW
  SET_NEW_DATA_STUDENT: 'SET_NEW_DATA_STUDENT',
  SET_DATA_STUDENT_ALL: 'SET_DATA_STUDENT_ALL',
  getStudentData: () => {
    return {
      type: actions.GET_DATA_STUDENT,
    };
  },
  setStudentData: data => {
    return {
      type: actions.SET_DATA_STUDENT,
      data,
    };
  },
  setStudentAllData: data => {
    return {
      type: actions.SET_DATA_STUDENT_ALL,
      data,
    };
  },
  setStudentDetailData: data => {
    return {
      type: actions.SET_DATA_DETAIL_STUDENT,
      data,
    };
  },
  getDataBegin: () => {
    return {
      type: actions.GET_DATA_BEGIN,
    };
  },
  getDataFinish: () => {
    return {
      type: actions.GET_DATA_FINISH,
    };
  },
  setDeleteStudent: data => {
    return {
      type: actions.DELETE_DATA_STUDENT,
      data,
    };
  },
  setEditStudentData: data => {
    return {
      type: actions.EDIT_DATA_STUDENT,
      data,
    };
  },
  setNewStudentData: data => {
    return {
      type: actions.SET_NEW_DATA_STUDENT,
      data,
    };
  },
  setNewStudentData: data => {
    return {
      type: actions.SET_NEW_DATA_STUDENT,
      data,
    };
  },
};


export default actions;
