const actions = {
    GET_DATA_TYPE: 'GET_DATA_TYPE',
    SET_DATA_TYPE: 'SET_DATA_TYPE',
    EDIT_DATA_TYPE: 'EDIT_DATA_TYPE',
    DELETE_DATA_TYPE: 'DELETE_DATA_TYPE',
    GET_DATA_FINISH: 'GET_DATA_FINISH',
    GET_DATA_BEGIN: 'GET_DATA_BEGIN',
    SET_NEW_DATA_TYPE: 'SET_NEW_DATA_TYPE',
    GET_DATA_TYPES_ALL: 'GET_DATA_TYPES_ALL',
  
    getTypeData: () => {
      return {
        type: actions.GET_DATA_TYPE,
      };
    },
    setAllTypes: data => {
      return {
        type: actions.GET_DATA_TYPES_ALL,
        data,
      };
    },
    setTypeData: data => {
      return {
        type: actions.SET_DATA_TYPE,
        data,
      };
    },
    getDataBegin: () => {
      return {
        type: actions.GET_DATA_BEGIN,
      };
    },
    getDataFinish: () => {
      return {
        type: actions.GET_DATA_FINISH,
      };
    },
    setDeleteType: data => {
      return {
        type: actions.DELETE_DATA_TYPE,
        data,
      };
    },
    setEditTypeData: data => {
      return {
        type: actions.EDIT_DATA_TYPE,
        data,
      };
    },
    setNewTypeData: data => {
      return {
        type: actions.SET_NEW_DATA_TYPE,
        data,
      };
    },
  };
  
  export default actions;