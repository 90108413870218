const actions = {
  GET_DATA_CON: 'GET_DATA_CON',
  SET_DATA_CON: 'SET_DATA_CON',
  DELETE_DATA_CON: 'DELETE_DATA_CON',
  EDIT_DATA_CON: 'EDIT_DATA_CON',
  SET_NEW_DATA_CON: 'SET_NEW_DATA_CON',
  SET_TOTAL_CON: 'SET_TOTAL_CON',
  GET_DATA_BEGIN: 'GET_DATA_BEGIN',
  GET_DATA_FINISH: 'GET_DATA_FINISH',
  getConData: () => {
    return {
      type: actions.GET_DATA_CON,
    };
  },
  setConData: data => {
    return {
      type: actions.SET_DATA_CON,
      data,
    };
  },
  setConTotal: data => {
    return {
      type: actions.SET_TOTAL_CON,
      data,
    };
  },
  setNewConData: data => {
    return {
      type: actions.SET_NEW_DATA_CON,
      data,
    };
  },
  getDataBegin: () => {
    return {
      type: actions.GET_DATA_BEGIN,
    };
  },
  getDataFinish: () => {
    return {
      type: actions.GET_DATA_FINISH,
    };
  },
  setDeleteConv: data => {
    return {
      type: actions.DELETE_DATA_CON,
      data,
    };
  },
  setEditConData: data => {
    return {
      type: actions.EDIT_DATA_CON,
      data,
    };
  },
};

export default actions;
