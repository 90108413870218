import React, { useEffect } from 'react';
import propTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import { userGetData } from '../../redux/users/actionCreator';
import { getBranchs, getCourses } from '../../redux/core/actionCreator';

const ProtectedRoute = ({ component, path }) => {
  const isLoggedIn = useSelector(state => state.auth.login);
  const isLoading = useSelector(state => state.auth.loading);

  const dispatch = useDispatch();
  useEffect(() => {
    let unmounted = false;

    const functionGet = async () => {
      dispatch(userGetData());
      dispatch(getBranchs());
      dispatch(getCourses());
    };
    if (!unmounted) {
      functionGet();
    }
    return () => (unmounted = true);
  }, []);

  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spin style={{ marginTop: '10%', width: '120px' }} size="large" />
      </div>
    );
  }

  return isLoggedIn ? <Route component={component} path={path} /> : <Redirect to="/" />;
};

ProtectedRoute.propTypes = {
  component: propTypes.object.isRequired,
  path: propTypes.string.isRequired,
};

export default ProtectedRoute;
