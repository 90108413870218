import actions from './actions';

const {
  GET_DATA_BAR,
  SET_DATA_BAR
} = actions;

const initialState = {
  data: [],
};

const BardaReducer = (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case GET_DATA_BAR:
      return {
        ...state
      };
    case SET_DATA_BAR:
      return {
        ...state,
        data
      };
    default:
      return state;
  }
};
export { BardaReducer };
