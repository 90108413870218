import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { message } from 'antd'
const {
  setSections,
  setSectionFilter,
  setTiposUsuarios,
  setMunicipios,
  setDistritosLocales,
  setEstructuras,
  setMobilizers,
  setAnalytics,
  setResponsables,
  setCapturistas,
  setColonias,
  setCooGral,
  //NEW
  setBranchs,
  setCourses,
  setSales,
  filterSales,
  setReport,
  addReport,
} = actions;

const getSections = () => {
  return async dispatch => {
    try {
      const sections = await DataService.get('getSections')
      const sectionsData = sections.data.data.map(mov => {
        mov.text = `${mov.seccion}`;
        mov.value = `${mov.seccion}`;
        return mov;
      });
      dispatch(setSections(sections.data.data));
      dispatch(setSectionFilter(sectionsData));
    } catch (err) {
      console.log(err)
    }
  };
};

const getFilterSales = (params) => {
  return async dispatch => {
    try {
      dispatch(filterSales(params));
    } catch (err) {
      console.log(err)
    }
  };
};

const getTypesUsers = () => {
  return async dispatch => {
    try {
      const tiposUsuarios = await DataService.get('getTypesUsers')
      dispatch(setTiposUsuarios(tiposUsuarios.data.data));
    } catch (err) {
      console.log(err)
    }
  };
};

const getMunicipalities = () => {
  return async dispatch => {
    try {
      const municipios = await DataService.get('getMunicipalities')
      dispatch(setMunicipios(municipios.data.data));
    } catch (err) {
      console.log(err)
    }
  };
};

const getDL = () => {
  return async dispatch => {
    try {
      const distritosLocales = await DataService.get('getDL')
      dispatch(setDistritosLocales(distritosLocales.data.data));
    } catch (err) {
      console.log(err)
    }
  };
};

const getStructures = () => {
  return async dispatch => {
    try {
      const estructuras = await DataService.get('getStructures')
      dispatch(setEstructuras(estructuras.data.data));
    } catch (err) {
      console.log(err)
    }
  };
};

const getMovilization = (textoBusqueda = '', seccion = '') => {
  return async dispatch => {
    try {
      const movilizadores = await DataService.get(`getMobilizers?textoBusqueda=${textoBusqueda}&seccion=${seccion}`)
      const dataMov = movilizadores.data.data.map(mov => {
        mov.nombreCompleto = `${mov.nombre} ${mov.apellidoPaterno} ${mov.apellidoMaterno}`
        return mov;
      });
      dispatch(setMobilizers(dataMov));
    } catch (err) {
      console.log(err)
    }
  };
};

const getCapturistas = () => {
  return async dispatch => {
    try {
      const movilizadores = await DataService.get('getCapturistas')
      const dataMov = movilizadores.data.data.map(mov => {
        mov.nombreCompleto = `${mov.nombre} ${mov.apellidoPaterno} ${mov.apellidoMaterno}`
        return mov;
      });
      dispatch(setCapturistas(dataMov));
    } catch (err) {
      console.log(err)
    }
  };
};

const getResponsables = () => {
  return async dispatch => {
    try {
      const movilizadores = await DataService.get('getResponsables')
      const dataMov = movilizadores.data.data.map(mov => {
        mov.nombreCompleto = `${mov.nombre} ${mov.apellidoPaterno} ${mov.apellidoMaterno}`
        return mov;
      });
      dispatch(setResponsables(dataMov));
    } catch (err) {
      console.log(err)
    }
  };
};

const getCoordinadoresGenerales = () => {
  return async dispatch => {
    try {
      const coordGrales = await DataService.get('getCoordinadores')
      console.log('coordGrales', coordGrales);
      if (coordGrales.status !== 200) {
        throw new Error(coordGrales ?.data ?.message || coordGrales.statusText)
      }
      const dataCG = coordGrales.data.data.map(coo => {
        coo.nombreCompleto = `${coo.nombre} ${coo.apellidoPaterno} ${coo.apellidoMaterno}`
        return coo;
      });
      dispatch(setCooGral(dataCG));
    } catch (err) {
      message.error(err ?.message || err)
      console.log(err)
    }
  };
};

const getAnalytics = () => {
  return async dispatch => {
    try {
      const analytics = await DataService.get('getAnalytics')
      dispatch(setAnalytics(analytics.data.data));
    } catch (err) {
      console.log(err)
    }
  };
};

const getColonies = () => {
  return async dispatch => {
    try {
      const colonias = await DataService.get('colonias')
      dispatch(setColonias(colonias.data.data));
    } catch (err) {
      console.log(err)
    }
  };
};

const getBranchs = () => {
  return async dispatch => {
    try {
      const branchs = await DataService.get('branch/getAll')
      dispatch(setBranchs(branchs.data));
    } catch (err) {
      console.log(err)
    }
  };
};

const getCourses = () => {
  return async dispatch => {
    try {
      const branchs = await DataService.get('courses/getAll')
      dispatch(setCourses(branchs.data));
    } catch (err) {
      console.log(err)
    }
  };
};

const getSales = (params) => {
  return async dispatch => {
    try {
      console.log('params', params)
      const sales = await DataService.get(`sales/getPending?student_id=${params.student_id}&saleTypes=${params.saleTypes}`)
      dispatch(setSales(sales.data));
    } catch (err) {
      console.log(err)
    }
  };
};

const getReport = (params) => {
  return async dispatch => {
    try {
      const sales = await DataService.get(`box/getReport?date=${params.date}`)
      dispatch(setReport(sales.data));
    } catch (err) {
      console.log(err)
    }
  };
};

const generateReport = (params) => {
  return async dispatch => {
    try {
      await DataService.post(`box`,params);
      // dispatch(addReport(dataBox.data));
    } catch (err) {
      console.log(err)
    }
  };
};

export {
  getBranchs,
  getCourses,
  getSales,
  getFilterSales,
  getReport,
  generateReport,
  //OLD
  getColonies,
  getSections,
  getTypesUsers,
  getMunicipalities,
  getDL,
  getStructures,
  getMovilization,
  getAnalytics,
  getResponsables,
  getCapturistas,
  getCoordinadoresGenerales
};
