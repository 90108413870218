const actions = {
  GET_DATA_TEACHER: 'GET_DATA_TEACHER',
  SET_DATA_TEACHER: 'SET_DATA_TEACHER',
  EDIT_DATA_TEACHER: 'EDIT_DATA_TEACHER',
  DELETE_DATA_TEACHER: 'DELETE_DATA_TEACHER',
  GET_DATA_FINISH: 'GET_DATA_FINISH',
  GET_DATA_BEGIN: 'GET_DATA_BEGIN',
  //NEW
  SET_NEW_DATA_TEACHER: 'SET_NEW_DATA_TEACHER',
  GET_DATA_TEACHERS_ALL: 'GET_DATA_TEACHERS_ALL',

  getTeacherData: () => {
    return {
      type: actions.GET_DATA_TEACHER,
    };
  },
  setAllTeachers: data => {
    return {
      type: actions.GET_DATA_TEACHERS_ALL,
      data,
    };
  },
  setTeacherData: data => {
    return {
      type: actions.SET_DATA_TEACHER,
      data,
    };
  },
  getDataBegin: () => {
    return {
      type: actions.GET_DATA_BEGIN,
    };
  },
  getDataFinish: () => {
    return {
      type: actions.GET_DATA_FINISH,
    };
  },
  setDeleteTeacher: data => {
    return {
      type: actions.DELETE_DATA_TEACHER,
      data,
    };
  },
  setEditTeacherData: data => {
    return {
      type: actions.EDIT_DATA_TEACHER,
      data,
    };
  },
  setNewTeacherData: data => {
    return {
      type: actions.SET_NEW_DATA_TEACHER,
      data,
    };
  },
  setNewTeacherData: data => {
    return {
      type: actions.SET_NEW_DATA_TEACHER,
      data,
    };
  },
};


export default actions;
