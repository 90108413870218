const actions = {
  GET_DATA_EXPENSE: 'GET_DATA_EXPENSE',
  SET_DATA_EXPENSE: 'SET_DATA_EXPENSE',
  EDIT_DATA_EXPENSE: 'EDIT_DATA_EXPENSE',
  DELETE_DATA_EXPENSE: 'DELETE_DATA_EXPENSE',
  GET_DATA_FINISH: 'GET_DATA_FINISH',
  GET_DATA_BEGIN: 'GET_DATA_BEGIN',
  //NEW
  SET_NEW_DATA_EXPENSE: 'SET_NEW_DATA_EXPENSE',
  GET_DATA_EXPENSES_ALL: 'GET_DATA_EXPENSES_ALL',

  getExpenseData: () => {
    return {
      type: actions.GET_DATA_EXPENSE,
    };
  },
  setAllExpenses: data => {
    return {
      type: actions.GET_DATA_EXPENSES_ALL,
      data,
    };
  },
  setExpenseData: data => {
    return {
      type: actions.SET_DATA_EXPENSE,
      data,
    };
  },
  getDataBegin: () => {
    return {
      type: actions.GET_DATA_BEGIN,
    };
  },
  getDataFinish: () => {
    return {
      type: actions.GET_DATA_FINISH,
    };
  },
  setDeleteExpense: data => {
    return {
      type: actions.DELETE_DATA_EXPENSE,
      data,
    };
  },
  setEditExpenseData: data => {
    return {
      type: actions.EDIT_DATA_EXPENSE,
      data,
    };
  },
  setNewExpenseData: data => {
    return {
      type: actions.SET_NEW_DATA_EXPENSE,
      data,
    };
  },
  setNewExpenseData: data => {
    return {
      type: actions.SET_NEW_DATA_EXPENSE,
      data,
    };
  },
};


export default actions;
