const actions = {
  GET_DATA_ADVISOR: 'GET_DATA_ADVISOR',
  SET_DATA_ADVISOR: 'SET_DATA_ADVISOR',
  EDIT_DATA_ADVISOR: 'EDIT_DATA_ADVISOR',
  DELETE_DATA_ADVISOR: 'DELETE_DATA_ADVISOR',
  GET_DATA_FINISH: 'GET_DATA_FINISH',
  GET_DATA_BEGIN: 'GET_DATA_BEGIN',
  //NEW
  SET_NEW_DATA_ADVISOR: 'SET_NEW_DATA_ADVISOR',
  GET_DATA_ADVISORS_ALL: 'GET_DATA_ADVISORS_ALL',

  getAdvisorData: () => {
    return {
      type: actions.GET_DATA_ADVISOR,
    };
  },
  setAllAdvisors: data => {
    return {
      type: actions.GET_DATA_ADVISORS_ALL,
      data,
    };
  },
  setAdvisorData: data => {
    return {
      type: actions.SET_DATA_ADVISOR,
      data,
    };
  },
  getDataBegin: () => {
    return {
      type: actions.GET_DATA_BEGIN,
    };
  },
  getDataFinish: () => {
    return {
      type: actions.GET_DATA_FINISH,
    };
  },
  setDeleteAdvisor: data => {
    return {
      type: actions.DELETE_DATA_ADVISOR,
      data,
    };
  },
  setEditAdvisorData: data => {
    return {
      type: actions.EDIT_DATA_ADVISOR,
      data,
    };
  },
  setNewAdvisorData: data => {
    return {
      type: actions.SET_NEW_DATA_ADVISOR,
      data,
    };
  },
  setNewAdvisorData: data => {
    return {
      type: actions.SET_NEW_DATA_ADVISOR,
      data,
    };
  },
};


export default actions;
