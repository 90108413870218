import actions from './actions';

const {
  GET_DATA_USER,
  SET_DATA_ADMIN,
  DELETE_DATA_USER,
  EDIT_DATA_USER,
  GET_DATA_BEGIN,
  GET_DATA_FINISH,
  //NEW
  SET_NEW_DATA_USER,
} = actions;

const initialState = {
  data: [],
  total: 0,
  loading: false,
};

const adminReducer = (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case GET_DATA_USER:
      return {
        ...state
      };
    case SET_DATA_ADMIN:
      return {
        ...state,
        data: data.dataUser,
        total: data.total,
        loading: false,
      };
    case DELETE_DATA_USER:
      return {
        ...state,
        data: [...state.data.filter(person => person.id !== data.id)]
      };
    case EDIT_DATA_USER:
      const index = state.data.findIndex(person => person.id === data.id);
      let newArray = [...state.data];
      newArray[index] = data;
      return {
        ...state,
        data: newArray
      };
    case SET_NEW_DATA_USER:
      return {
        ...state,
        data: [data, ...state.data],
      };
    case GET_DATA_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_DATA_FINISH:
      return {
        ...state,
        loading: false,
      };
    case SET_NEW_DATA_USER:
      return {
        ...state,
        data: [data, ...state.data],
      };
    default:
      return state;
  }
};
export { adminReducer };
