const actions = {
  GET_DATA_USER: 'GET_DATA_USER',
  SET_DATA_USER: 'SET_DATA_USER',
  getUserData: () => {
    return {
      type: actions.GET_DATA_USER,
    };
  },
  setUserData: data => {
    return {
      type: actions.SET_DATA_USER,
      data,
    };
  },
  
};

export default actions;
