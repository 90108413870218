import actions from './actions';

const {
  GET_DATA_STUDENT,
  SET_DATA_STUDENT,
  DELETE_DATA_STUDENT,
  EDIT_DATA_STUDENT,
  GET_DATA_BEGIN,
  GET_DATA_FINISH,
  //NEW
  SET_NEW_DATA_STUDENT,
  SET_DATA_DETAIL_STUDENT,
  SET_DATA_STUDENT_ALL,
} = actions;

const initialState = {
  data: [],
  detail: {
    student: {},
    lessons: [],
    sales: [],
    payments: [],
    addUser: []
  },
  allStudents: [],
  total: 0,
  loading: false,
};

const studentsReducer = (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case GET_DATA_STUDENT:
      return {
        ...state
      };
    case SET_DATA_STUDENT:
      return {
        ...state,
        data: data.results,
        total: data.totalResults,
        loading: false,
      };
    case SET_DATA_STUDENT_ALL:
      return {
        ...state,
        allStudents: data,
        loading: false,
      };
    case SET_DATA_DETAIL_STUDENT:
      return {
        ...state,
        detail: data,
        loading: false,
      };
    case DELETE_DATA_STUDENT:
      return {
        ...state,
        data: [...state.data.filter(person => person.id !== data.id)]
      };
    case EDIT_DATA_STUDENT:
      const index = state.data.findIndex(person => person.id === data.id);
      let newArray = [...state.data];
      newArray[index] = data;
      return {
        ...state,
        data: newArray
      };
    case SET_NEW_DATA_STUDENT:
      return {
        ...state,
        data: [data, ...state.data],
      };
    case GET_DATA_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_DATA_FINISH:
      return {
        ...state,
        loading: false,
      };
    case SET_NEW_DATA_STUDENT:
      return {
        ...state,
        data: [data, ...state.data],
      };
    default:
      return state;
  }
};
export { studentsReducer };
