const actions = {
  GET_DATA_VEH: 'GET_DATA_VEH',
  SET_DATA_VEH: 'SET_DATA_VEH',
  getVehData: () => {
    return {
      type: actions.GET_DATA_VEH,
    };
  },
  setVehData: data => {
    return {
      type: actions.SET_DATA_VEH,
      data,
    };
  },
  
};

export default actions;
