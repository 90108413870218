import actions from './actions';

const initialState = {
  descriptions: [],
  types: [],
  loading: false,
};

const expenseOptionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_DATA_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_DATA_FINISH:
      return {
        ...state,
        loading: false,
      };
    case actions.SET_DESCRIPTION_TYPES:
      return {
        ...state,
        descriptions: action.payload,
      };
    case actions.SET_ALL_TYPES:
      return {
        ...state,
        types: action.payload,
      };
    case actions.SET_DATA_DESCRIPTION:
      return {
        ...state,
        descriptions: action.data,
      };
    case actions.GET_DATA_DESCRIPTIONS_ALL:
      return {
        ...state,
        descriptions: action.data,
      };
    case actions.SET_NEW_DATA_DESCRIPTION:
      return {
        ...state,
        descriptions: [...state.descriptions, action.data],
      };
    case actions.EDIT_DATA_DESCRIPTION:
      return {
        ...state,
        descriptions: state.descriptions.map(description =>
          description.id === action.data.id ? action.data : description
        ),
      };
    case actions.DELETE_DATA_DESCRIPTION:
      return {
        ...state,
        descriptions: state.descriptions.filter(description => description.id !== action.data),
      };
    default:
      return state;
  }
};

export default expenseOptionsReducer;