const actions = {
  GET_DATA_MOV: 'GET_DATA_USER',
  SET_DATA_MOV: 'SET_DATA_MOV',
  SET_NEW_DATA_MOV: 'SET_NEW_DATA_MOV',
  EDIT_DATA_MOV: 'EDIT_DATA_MOV',
  DELETE_DATA_MOV: 'DELETE_DATA_MOV',
  GET_DATA_FINISH: 'GET_DATA_FINISH',
  GET_DATA_BEGIN: 'GET_DATA_BEGIN',
  getMovData: () => {
    return {
      type: actions.GET_DATA_USER,
    };
  },
  setMovData: data => {
    return {
      type: actions.SET_DATA_MOV,
      data,
    };
  },
  getDataBegin: () => {
    return {
      type: actions.GET_DATA_BEGIN,
    };
  },
  getDataFinish: () => {
    return {
      type: actions.GET_DATA_FINISH,
    };
  },
  setDeleteMov: data => {
    return {
      type: actions.DELETE_DATA_MOV,
      data,
    };
  },
  setEditMovData: data => {
    return {
      type: actions.EDIT_DATA_MOV,
      data,
    };
  },
  setNewMovData: data => {
    return {
      type: actions.SET_NEW_DATA_MOV,
      data,
    };
  },
};


export default actions;
