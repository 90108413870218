import Cookies from 'js-cookie';
import actions from './actions';
import { removeItem, setItem, getItem } from '../../utility/localStorageControl';
import { DataService } from '../../config/dataService/dataService';

const { loginBegin, loginSuccess, loginErr, logoutBegin, logoutSuccess, logoutErr } = actions;

const login = (values) => {
  return async dispatch => {
    try {
      dispatch(loginBegin());
      const response = await DataService.post('auth/login', values);
      if (response.status === 200) {
          Cookies.set('logedIn', true);
          dispatch(loginSuccess(true));
          setItem('access_token',response.data.tokens?.access?.token)
          setItem('refresh_token',response.data.tokens?.refresh?.token)
          setItem('user',response.data.user)
          return dispatch(loginSuccess(true));
      }else{
          Cookies.set('logedIn', false);
          return dispatch(loginErr(response.data))
          
      }

    } catch (err) {
      dispatch(loginErr(err));
    }
  };
};

const logOut = () => {
  return async dispatch => {
    try {
      dispatch(logoutBegin());
      const response = await DataService.post('auth/logout', {
        refreshToken: getItem('refresh_token')
      });
      Cookies.remove('logedIn');
      removeItem('access_token');
      removeItem('refresh_token');
      removeItem('user');
      dispatch(logoutSuccess(null));
    } catch (err) {
      dispatch(logoutErr(err));
    }
  };
};

export { login, logOut };
