import { combineReducers } from 'redux';
import themeUsersReducer from './themeUsers/reducers';
import { readMessageReducer } from './message/reducers';
import { readNotificationReducer } from './notification/reducers';
import authReducer from './authentication/reducers';
import ChangeLayoutMode from './themeLayout/reducers';
import { userReducer } from './users/reducers';
import { VehicleReducer } from './vehicles/reducers';
import { BardaReducer } from './bardas/reducers';
import { movilizationsReducer } from './movilizations/reducers';
import { convencidosReducer } from './convencidos/reducers';
import { headerSearchReducer } from './headerSearch/reducers';
import { coreReducer } from './core/reducers';
import chartContentReducer from './chartContent/reducers';

import { adminReducer } from './userAdmin/reducers';
import { branchsReducer } from './branchs/reducers';
import { coursesReducer } from './courses/reducers';
import { modulesReducer } from './modules/reducers';
import { teachersReducer } from './teachers/reducers';
import { studentsReducer } from './students/reducers';
import { groupsReducer } from './groups/reducers';
import { expensesReducer } from './expenses/reducers';
import { paymentReducer } from './payments/reducers';
import { rpsReducer } from './rps/reducers';
import {reportReducer} from './reportes/reducers';
import {timetablesReducer} from './timetables/reducers';
import {advisorsReducer} from './advisors/reducers';
import { typesReducer } from './types/reducers';
import expenseOptionsReducer from './expensesOptions/reducers';
import {productdReducer} from './products/reducers';
import {inventoryReducer} from './inventories/reducers';


const rootReducers = combineReducers({  
  themeUsers: themeUsersReducer,
  headerSearchData: headerSearchReducer,
  message: readMessageReducer,
  notification: readNotificationReducer,
  users: userReducer,
  movilizations: movilizationsReducer,
  convencidos: convencidosReducer,
  vehicles: VehicleReducer,
  bardas: BardaReducer,
  auth: authReducer,
  ChangeLayoutMode,
  chartContent: chartContentReducer, 
  core: coreReducer,
  //NEW
  userAdmin: adminReducer,
  branchs: branchsReducer,
  courses: coursesReducer,
  modules: modulesReducer,
  teachers: teachersReducer,
  students: studentsReducer,
  groups: groupsReducer,
  expenses: expensesReducer,
  payments: paymentReducer,
  rps: rpsReducer,
  reportes: reportReducer,
  timetables: timetablesReducer,
  advisors: advisorsReducer,
  types: typesReducer,
  expenseOptions: expenseOptionsReducer,
  products: productdReducer,
  inventories: inventoryReducer,
});

export default rootReducers;
