const actions = {
  GET_DATA_PAYMENT: 'GET_DATA_PAYMENT',
  SET_DATA_PAYMENT: 'SET_DATA_PAYMENT',
  EDIT_DATA_PAYMENT: 'EDIT_DATA_PAYMENT',
  DELETE_DATA_PAYMENT: 'DELETE_DATA_PAYMENT',
  GET_DATA_FINISH: 'GET_DATA_FINISH',
  GET_DATA_BEGIN: 'GET_DATA_BEGIN',
  //NEW
  SET_NEW_DATA_PAYMENT: 'SET_NEW_DATA_PAYMENT',
  GET_DATA_PAYMENTS_ALL: 'GET_DATA_PAYMENTS_ALL',
  SET_DATA_WEEK: 'SET_DATA_WEEK',
  getPaymentData: () => {
    return {
      type: actions.GET_DATA_PAYMENT,
    };
  },
  setAllPayment: data => {
    return {
      type: actions.GET_DATA_PAYMENTS_ALL,
      data,
    };
  },
  setPaymentData: data => {
    return {
      type: actions.SET_DATA_PAYMENT,
      data,
    };
  },
  setWeekData: data => {
    return {
      type: actions.SET_DATA_WEEK,
      data,
    };
  },
  getDataBegin: () => {
    return {
      type: actions.GET_DATA_BEGIN,
    };
  },
  getDataFinish: () => {
    return {
      type: actions.GET_DATA_FINISH,
    };
  },
  setDeletePayment: data => {
    return {
      type: actions.DELETE_DATA_PAYMENT,
      data,
    };
  },
  setEditPaymentData: data => {
    return {
      type: actions.EDIT_DATA_PAYMENT,
      data,
    };
  },
  setNewPaymentData: data => {
    return {
      type: actions.SET_NEW_DATA_PAYMENT,
      data,
    };
  },
  setNewPaymentData: data => {
    return {
      type: actions.SET_NEW_DATA_PAYMENT,
      data,
    };
  },
};


export default actions;
