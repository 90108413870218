import actions from './actions';

const {
  GET_DATA_RP,
  SET_DATA_RP,
  DELETE_DATA_RP,
  EDIT_DATA_RP,
  GET_DATA_BEGIN,
  GET_DATA_FINISH,
  //NEW
  SET_NEW_DATA_RP,
  GET_DATA_RPS_ALL,
  SET_RP_NEW_PASSWORD
} = actions;

const initialState = {
  data: [],
  rps: [],
  rps: [],
  total: 0,
  loading: false,
};

const rpsReducer = (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case GET_DATA_RP:
      return {
        ...state
      };
    case SET_DATA_RP:
      return {
        ...state,
        data: data.results,
        total: data.totalResults,
        loading: false,
      };
    case GET_DATA_RPS_ALL:
      return {
        ...state,
        rps: data,
        loading: false,
      };
    case DELETE_DATA_RP:
      return {
        ...state,
        data: [...state.data.filter(person => person.id !== data.id)]
      };
    case EDIT_DATA_RP:
      const index = state.data.findIndex(person => person.id === data.id);
      let newArray = [...state.data];
      newArray[index] = data;
      return {
        ...state,
        data: newArray
      };
    case SET_NEW_DATA_RP:
      return {
        ...state,
        data: [data, ...state.data],
      };
    case GET_DATA_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_DATA_FINISH:
      return {
        ...state,
        loading: false,
      };
    case SET_NEW_DATA_RP:
      return {
        ...state,
        data: [data, ...state.data],
      };
      case SET_RP_NEW_PASSWORD:
        return {
          ...state,
          data: state.data.map(person =>
            person.id === data.id ? { ...person, password: data.password } : person
          ),
        };
        default:
        return state;
      }
};

export { rpsReducer };
