const actions = {
  GET_DATA_PRODUCT: 'GET_DATA_PRODUCT',
  SET_DATA_PRODUCT: 'SET_DATA_PRODUCT',
  SET_DATA_PRODUCT_ALL: 'SET_DATA_PRODUCT_ALL',
  EDIT_DATA_PRODUCT: 'EDIT_DATA_PRODUCT',
  DELETE_DATA_PRODUCT: 'DELETE_DATA_PRODUCT',
  GET_DATA_FINISH: 'GET_DATA_FINISH',
  GET_DATA_BEGIN: 'GET_DATA_BEGIN',
  //NEW
  SET_NEW_DATA_PRODUCT: 'SET_NEW_DATA_PRODUCT',
  getProductData: () => {
    return {
      type: actions.GET_DATA_PRODUCT,
    };
  },
  setProductData: data => {
    return {
      type: actions.SET_DATA_PRODUCT,
      data,
    };
  },
  setProductAllData: data => {
    return {
      type: actions.SET_DATA_PRODUCT_ALL,
      data,
    };
  },
  getDataBegin: () => {
    return {
      type: actions.GET_DATA_BEGIN,
    };
  },
  getDataFinish: () => {
    return {
      type: actions.GET_DATA_FINISH,
    };
  },
  setDeleteProduct: data => {
    return {
      type: actions.DELETE_DATA_PRODUCT,
      data,
    };
  },
  setEditProductData: data => {
    return {
      type: actions.EDIT_DATA_PRODUCT,
      data,
    };
  },
  setNewProductData: data => {
    return {
      type: actions.SET_NEW_DATA_PRODUCT,
      data,
    };
  },
  setNewProductData: data => {
    return {
      type: actions.SET_NEW_DATA_PRODUCT,
      data,
    };
  },
};


export default actions;
