const actions = {
  GET_DATA_RP: 'GET_DATA_RP',
  SET_DATA_RP: 'SET_DATA_RP',
  EDIT_DATA_RP: 'EDIT_DATA_RP',
  DELETE_DATA_RP: 'DELETE_DATA_RP',
  GET_DATA_FINISH: 'GET_DATA_FINISH',
  GET_DATA_BEGIN: 'GET_DATA_BEGIN',
  //NEW
  SET_NEW_DATA_RP: 'SET_NEW_DATA_RP',
  GET_DATA_RPS_ALL: 'GET_DATA_RPS_ALL',
  SET_RP_NEW_PASSWORD: 'SET_RP_NEW_PASSWORD',

  getRpData: () => {
    return {
      type: actions.GET_DATA_RP,
    };
  },
  setAllRps: data => {
    return {
      type: actions.GET_DATA_RPS_ALL,
      data,
    };
  },
  setRpData: data => {
    return {
      type: actions.SET_DATA_RP,
      data,
    };
  },
  getDataBegin: () => {
    return {
      type: actions.GET_DATA_BEGIN,
    };
  },
  getDataFinish: () => {
    return {
      type: actions.GET_DATA_FINISH,
    };
  },
  setDeleteTeacher: data => {
    return {
      type: actions.DELETE_DATA_RP,
      data,
    };
  },
  setEditRpData: data => {
    return {
      type: actions.EDIT_DATA_RP,
      data,
    };
  },
  setNewRpData: data => {
    return {
      type: actions.SET_NEW_DATA_RP,
      data,
    };
  },
  setRpNewPassword: data => {
    return {
      type: actions.SET_RP_NEW_PASSWORD,
      data,
    };
  },
};


export default actions;
