const actions = {
  GET_DATA_COURSE: 'GET_DATA_COURSE',
  SET_DATA_COURSE: 'SET_DATA_COURSE',
  EDIT_DATA_COURSE: 'EDIT_DATA_COURSE',
  DELETE_DATA_COURSE: 'DELETE_DATA_COURSE',
  GET_DATA_FINISH: 'GET_DATA_FINISH',
  GET_DATA_BEGIN: 'GET_DATA_BEGIN',
  //NEW
  SET_NEW_DATA_COURSE: 'SET_NEW_DATA_COURSE',
  getCourseData: () => {
    return {
      type: actions.GET_DATA_COURSE,
    };
  },
  setCourseData: data => {
    return {
      type: actions.SET_DATA_COURSE,
      data,
    };
  },
  getDataBegin: () => {
    return {
      type: actions.GET_DATA_BEGIN,
    };
  },
  getDataFinish: () => {
    return {
      type: actions.GET_DATA_FINISH,
    };
  },
  setDeleteCourse: data => {
    return {
      type: actions.DELETE_DATA_COURSE,
      data,
    };
  },
  setEditCourseData: data => {
    return {
      type: actions.EDIT_DATA_COURSE,
      data,
    };
  },
  setNewCourseData: data => {
    return {
      type: actions.SET_NEW_DATA_COURSE,
      data,
    };
  },
  setNewCourseData: data => {
    return {
      type: actions.SET_NEW_DATA_COURSE,
      data,
    };
  },
};


export default actions;
