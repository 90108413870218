import React, { lazy, Suspense } from 'react';
import { Spin } from 'antd';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Dashboard from './dashboard';
import withAdminLayout from '../../layout/withAdminLayout';

const Administradores = lazy(() => import('../../container/admin/List'));
const Branchs = lazy(() => import('../../container/branchs/List'));
const Courses = lazy(() => import('../../container/courses/List'));
const Modules = lazy(() => import('../../container/modules/List'));
const Teachers = lazy(() => import('../../container/teachers/List'));
const Students = lazy(() => import('../../container/students/List'));
const StudentsDetail = lazy(() => import('../../container/students/Overview'));
const Groups = lazy(() => import('../../container/groups/List'));
const GroupDetail = lazy(() => import('../../container/groups/Overview'));
const Expenses = lazy(() => import('../../container/expenses/List'));
const Payments = lazy(() => import('../../container/payments/List'));
const Box = lazy(() => import('../../container/box/Dashboard'));
const Reportes = lazy(() => import('../../container/reportes/List'));
const Timetable = lazy(() => import('../../container/timetables/List'));
const Advisor = lazy(() => import('../../container/advisors/List'));
const Rps = lazy(() => import('../../container/rps/List'));
const Types = lazy(() => import('../../container/types/List'));
const Descriptions = lazy(() => import('../../container/expensesOptions/List'));
const Products = lazy(() => import('../../container/products/List'));
const Inventories = lazy(() => import('../../container/inventories/List'));
const Movements = lazy(() => import('../../container/movements/List'));

const Admin = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <Route path={path} component={Dashboard} />
        <Route path={`${path}/adms`} component={Administradores} />
        <Route path={`${path}/branchs`} component={Branchs} />
        <Route path={`${path}/courses`} component={Courses} />
        <Route path={`${path}/modules`} component={Modules} />
        <Route path={`${path}/teachers`} component={Teachers} />
        <Route path={`${path}/advisors`} component={Advisor} />
        <Route path={`${path}/students`} component={Students} />
        <Route exact path={`${path}/studentDetail/:id`} component={StudentsDetail} />
        <Route exact path={`${path}/groupDetail/:id`} component={GroupDetail} />
        <Route path={`${path}/groups`} component={Groups} />
        <Route path={`${path}/expenses`} component={Expenses} />
        <Route path={`${path}/payments`} component={Payments} />
        <Route path={`${path}/box`} component={Box} />
        <Route path={`${path}/rps`} component={Rps} />
        <Route path={`${path}/reportes`} component={Reportes} /> 
        <Route path={`${path}/timetables`} component={Timetable} /> 
        <Route path={`${path}/types`} component={Types} />
        <Route path={`${path}/descriptions`} component={Descriptions} /> 
        <Route path={`${path}/products`} component={Products} /> 
        <Route path={`${path}/inventories`} component={Inventories} /> 
        <Route path={`${path}/movements`} component={Movements} /> 
        {/* <Route path={`${path}/movilizadores`} component={Movilization} />
        <Route path={`${path}/vehiculos`} component={Vehiculos} />
        <Route path={`${path}/bardas`} component={Bardas} />
        <Route path={`${path}/convencidos`} component={Convencidos} />
        <Route path={`${path}/reportes`} component={Reportes} /> */}
      </Suspense>
    </Switch>
  );
};

export default withAdminLayout(Admin);
