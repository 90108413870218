const actions = {
  GET_DATA_DESCRIPTION: 'GET_DATA_DESCRIPTION',
  SET_DATA_DESCRIPTION: 'SET_DATA_DESCRIPTION',
  EDIT_DATA_DESCRIPTION: 'EDIT_DATA_DESCRIPTION',
  DELETE_DATA_DESCRIPTION: 'DELETE_DATA_DESCRIPTION',
  GET_DATA_FINISH: 'GET_DATA_FINISH',
  GET_DATA_BEGIN: 'GET_DATA_BEGIN',
  SET_NEW_DATA_DESCRIPTION: 'SET_NEW_DATA_DESCRIPTION',
  GET_DATA_DESCRIPTIONS_ALL: 'GET_DATA_DESCRIPTIONS_ALL',
  SET_DESCRIPTION_TYPES: 'SET_DESCRIPTION_TYPES',
  SET_ALL_TYPES: 'SET_ALL_TYPES',

  getDescriptionData: () => {
    return {
      type: actions.GET_DATA_DESCRIPTION,
    };
  },
  setAllDescriptions: data => {
    return {
      type: actions.GET_DATA_DESCRIPTIONS_ALL,
      data,
    };
  },
  setDescriptionData: data => {
    return {
      type: actions.SET_DATA_DESCRIPTION,
      data,
    };
  },
  getDataBegin: () => {
    return {
      type: actions.GET_DATA_BEGIN,
    };
  },
  getDataFinish: () => {
    return {
      type: actions.GET_DATA_FINISH,
    };
  },
  setDeleteDescription: data => {
    return {
      type: actions.DELETE_DATA_DESCRIPTION,
      data,
    };
  },
  setEditDescriptionData: data => {
    return {
      type: actions.EDIT_DATA_DESCRIPTION,
      data,
    };
  },
  setNewDescriptionData: data => {
    return {
      type: actions.SET_NEW_DATA_DESCRIPTION,
      data,
    };
  },
  setDescriptionTypes: types => {
    return {
      type: actions.SET_DESCRIPTION_TYPES,
      payload: types,
    };
  },
  setAllTypes: types => { 
    return {
      type: actions.SET_ALL_TYPES,
      payload: types,
    };
  },
};

export default actions;