import actions from './actions';
import actionsAuth from '../authentication/actions';
import { DataService } from '../../config/dataService/dataService';
import Cookies from 'js-cookie';
import { removeItem, setItem, getItem } from '../../utility/localStorageControl';

const {
  setUserData,
} = actions;

const {
  logoutSuccess,
} = actionsAuth;

const userGetData = () => {
  return async dispatch => {
    const response = await DataService.get('users/me')
    if (response.status === 401) {
      Cookies.remove('logedIn');
      removeItem('access_token');
      removeItem('refresh_token');
      removeItem('user');
      dispatch(logoutSuccess(null));
    }else {
      dispatch(setUserData(response.data));
    }
    try {
    } catch (err) {
    }
  };
};


export { userGetData };
