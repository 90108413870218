const actions = {
  GET_DATA_MODULE: 'GET_DATA_MODULE',
  SET_DATA_MODULE: 'SET_DATA_MODULE',
  EDIT_DATA_MODULE: 'EDIT_DATA_MODULE',
  DELETE_DATA_MODULE: 'DELETE_DATA_MODULE',
  GET_DATA_FINISH: 'GET_DATA_FINISH',
  GET_DATA_BEGIN: 'GET_DATA_BEGIN',
  //NEW
  SET_NEW_DATA_MODULE: 'SET_NEW_DATA_MODULE',
  getModuleData: () => {
    return {
      type: actions.GET_DATA_MODULE,
    };
  },
  setModuleData: data => {
    return {
      type: actions.SET_DATA_MODULE,
      data,
    };
  },
  getDataBegin: () => {
    return {
      type: actions.GET_DATA_BEGIN,
    };
  },
  getDataFinish: () => {
    return {
      type: actions.GET_DATA_FINISH,
    };
  },
  setDeleteModule: data => {
    return {
      type: actions.DELETE_DATA_MODULE,
      data,
    };
  },
  setEditModuleData: data => {
    return {
      type: actions.EDIT_DATA_MODULE,
      data,
    };
  },
  setNewModuleData: data => {
    return {
      type: actions.SET_NEW_DATA_MODULE,
      data,
    };
  },
  setNewModuleData: data => {
    return {
      type: actions.SET_NEW_DATA_MODULE,
      data,
    };
  },
};


export default actions;
