import actions from './actions';

const {
  GET_DATA_ADVISOR,
  SET_DATA_ADVISOR,
  DELETE_DATA_ADVISOR,
  EDIT_DATA_ADVISOR,
  GET_DATA_BEGIN,
  GET_DATA_FINISH,
  //NEW
  SET_NEW_DATA_ADVISOR,
  GET_DATA_ADVISORS_ALL
} = actions;

const initialState = {
  data: [],
  advisors: [],
  total: 0,
  loading: false,
};

const advisorsReducer = (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case GET_DATA_ADVISOR:
      return {
        ...state
      };
    case SET_DATA_ADVISOR:
      return {
        ...state,
        data: data.results,
        total: data.totalResults,
        loading: false,
      };
    case GET_DATA_ADVISORS_ALL:
      return {
        ...state,
        advisors: data,
        loading: false,
      };
    case DELETE_DATA_ADVISOR:
      return {
        ...state,
        data: [...state.data.filter(person => person.id !== data.id)]
      };
    case EDIT_DATA_ADVISOR:
      const index = state.data.findIndex(person => person.id === data.id);
      let newArray = [...state.data];
      newArray[index] = data;
      return {
        ...state,
        data: newArray
      };
    case SET_NEW_DATA_ADVISOR:
      return {
        ...state,
        data: [data, ...state.data],
      };
    case GET_DATA_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_DATA_FINISH:
      return {
        ...state,
        loading: false,
      };
    case SET_NEW_DATA_ADVISOR:
      return {
        ...state,
        data: [data, ...state.data],
      };
    default:
      return state;
  }
};
export { advisorsReducer };
