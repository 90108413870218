const actions = {
  GET_DATA_MOVEMENT: 'GET_DATA_MOVEMENT',
  GET_DATA_INVENTORY: 'GET_DATA_INVENTORY',
  SET_DATA_MOVEMENT: 'SET_DATA_MOVEMENT',
  SET_DATA_INVENTORY: 'SET_DATA_INVENTORY',
  GET_DATA_FINISH: 'GET_DATA_FINISH',
  GET_DATA_BEGIN: 'GET_DATA_BEGIN',
  //NEW
  SET_NEW_DATA_MOVEMENT: 'SET_NEW_DATA_MOVEMENT',
  getInventoryData: () => {
    return {
      type: actions.GET_DATA_MOVEMENT,
    };
  },
  getMovementsData: () => {
    return {
      type: actions.GET_DATA_INVENTORY,
    };
  },
  setMovementsData: data => {
    return {
      type: actions.SET_DATA_MOVEMENT,
      data,
    };
  },
  setInventoryData: data => {
    return {
      type: actions.SET_DATA_INVENTORY,
      data,
    };
  },
  getDataBegin: () => {
    return {
      type: actions.GET_DATA_BEGIN,
    };
  },
  getDataFinish: () => {
    return {
      type: actions.GET_DATA_FINISH,
    };
  },
  setNewMovementData: data => {
    return {
      type: actions.SET_NEW_DATA_MOVEMENT,
      data,
    };
  },
};


export default actions;
