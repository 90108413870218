const actions = {
  CREATE_REPORT_BEGIN: 'CREATE_REPORT_BEGIN',
  CREATE_REPORT_FINISH: 'CREATE_REPORT_FINISH',
  CREATE_REPORT_ERROR: 'CREATE_REPORT_ERROR',
  CREATE_REPORT_DASHBOARD: 'CREATE_REPORT_DASHBOARD',
 createReportBegin: data => {
    return {
      type: actions.CREATE_REPORT_BEGIN,
     
    };
  },
  createReportFinish: (data) => {
    return {
      type: actions.CREATE_REPORT_FINISH,
      data,
    };
  },
  createReportError: (data) => {
    return {
      type: actions.CREATE_REPORT_ERROR,
      data,
    };
  },
  setReportData: (data) => {
    return {
      type: actions.CREATE_REPORT_DASHBOARD,
      data,
    };
  },
};

export default actions;
